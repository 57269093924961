
.opc-segment-navigation {
	margin:0;
	color: #000;

	ul{
		@include mq($min-width: map-get($breakpoints, m)) {
			justify-content: flex-end;
		}
	}
	
    li {
        @extend .text;
    }
    a {
        font-size: 1.4rem;
        color: $theme-color-1;
        border: 1px solid #ccc;
        &:hover {
            background: #ccc;
        }
    }
    .segment-navigation-more
    /*.segment-navigation-prev,
    .segment-navigation-next */ {
        a {
            border-color: transparent;
        }
    }
    .disabled,
    .is-disabled {
    }
}