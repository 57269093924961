.opc-dropdown-button-content[data-op-caller-uuid]>.overlay-content>.divider {
    margin: 0;
}

.opc-dropdown-button-content[data-op-caller-uuid]>.overlay-content>.subtitle {
	color: $color-grey;
    padding: 1rem 0.7rem 0.7rem 0.7rem;
	font-size: 0.9em;
	text-transform: uppercase;
}

.opc-dropdown-button-content[data-op-caller-uuid]>.overlay-content>a {
    padding: 0.8rem 1.5rem;
    cursor: pointer;
}

.opc-dropdown-button-content[data-op-caller-uuid] {
	padding: 0;
}

.opc-item-list.dropdown-content{
	margin:0;
	.list-item{
		.list-item-column{
			padding-left: 3rem;
    		position: relative;
			>svg{
				position: absolute;
				left: 1rem;
				top: 0.7rem;
			}
		}
		.opc-button{
			width:100%;
		}
	}
}