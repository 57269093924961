  /** timeline box structure **/
  
  .timeline {
      list-style: none;
      padding: 0;
	  position: relative;
	  padding-bottom: 60px;
  }
  /**  Kompakte Ansicht fuer Dashboard etc.**/
  
  .timeline.compact {
      padding-right: 1px;
      li.activity:nth-of-type(2n+2) .timeline-panel {
          padding: 5px 10px 5px 10px;
      }
      li {
          margin-bottom: -50px;
          .timeline-panel {
              padding: 5px 10px 5px 10px;
              height: 125px;
			  .tl-body{
				overflow:hidden;
				max-height: 19px;
			  }
              .tl-body p,
              .tl-body ul {
				  -webkit-line-clamp: 1;
				  line-clamp:1;
              }
          }
          &.divider {
              margin: 60px 0 10px 0;
          }
      }
  }
  
  .timeline:before {
      top: 0;
      bottom: 0;
      position: absolute;
      content: " ";
      width: 4px;
      background-color: #eee;
      left: 50%;
      margin-left: -2px;
  }
  
  .tldate,
  .more {
      display: block;
      width: 200px;
      background: #fff;
      border: 1px solid #ccc;
      color: white;
      margin: 0 auto;
      padding: 10px 0;
      font-weight: normal;
      text-align: center;
	  border-radius:3px;
  }
  
  .tldate.today,
  .more.today {
      //background: $brand-color-1;
	  color: white;
  }

  .tldate.today{
	  cursor:default;
	  pointer-events: none;
	  background: #D5D5D5;
	  border-radius:30px;
	  color:#000;
  }
  
  .tldate,
  .more,
  .tldate:hover,
  .more:hover {
      background: #fff;
      color: rgba(0,0,0,0.8);
      text-decoration: none;
      cursor: pointer;
  }
  
  .tldate:hover,
  .more:hover {
      background: #fff;
      color: rgba(0,0,0,0.8);
  }
  
  .timeline li {
      margin-bottom: -60px;
      position: relative;
  }
  
  .timeline li.divider {
      margin: 70px 0 10px 0;
      position: relative;
      &.top {
          margin: 10px 0 10px 0;
      }
      &.bottom {
          margin: 70px 0 10px 0;
      }
  }
  
  .timeline li.divider:first-child {
      margin: 10px 0 10px 0;
  }
  
  .timeline li:before,
  .timeline li:after {
      content: " ";
      display: table;
  }
  
  .timeline li:after {
      clear: both;
  }
  
  .timeline li:before,
  .timeline li:after {
      content: " ";
      display: table;
  }
  /** timeline panels **/
  .timeline li .timeline-panel {
	width: calc(50% - 32px);
	width: -moz-calc(50% - 32px);
	width: -webkit-calc(50% - 32px);
	float: left;
	background: #fff;
	border: 1px solid #ccc;
	padding: 10px 10px 10px 10px;
	position: relative;
	height: 150px;
	cursor: pointer;
	z-index: 1;
	&:before {
		position: absolute;
		top: 26px;
		right: -15px;
		display: inline-block;
		border-top: 15px solid transparent;
		border-left: 15px solid #ccc;
		border-right: 0 solid #ccc;
		border-bottom: 15px solid transparent;
		content: " ";
	}
	&:after {
		position: absolute;
		top: 27px;
		right: -14px;
		display: inline-block;
		border-top: 14px solid transparent;
		border-left: 14px solid #fff;
		border-right: 0 solid #fff;
		border-bottom: 14px solid transparent;
		content: " ";
	}

	&:hover{
		background-color: #eee;

		&:after{
			border-left: 14px solid #eee;
			border-right: 0 solid #eee;
		}

		.tag-list .tag {
			background: #f8f8f8;
		}

	}
  }
  .timeline li.is-active .timeline-panel {
	background-color: rgb(228, 226, 226);

	&:after{
		border-left: 14px solid rgb(228, 226, 226);
		border-right: 0 solid rgb(228, 226, 226);
	}

	.tag-list .tag {

		background: #f8f8f8;
	}
  }
   /*
  .timeline li.done .timeline-panel {
      opacity: 0.7;
  }*/
  
  
  .timeline li .timeline-panel.noarrow:before,
  .timeline li .timeline-panel.noarrow:after {
      top: 0;
      right: 0;
      display: none;
      border: 0;
  }
  
  .timeline li .timeline-panel .tl-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 5px 10px;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: flex-start;
      align-items: flex-start;
      .left {
          order: 0;
          flex: 0 1 auto;
          align-self: flex-end;
          padding: 0;
      }
      .right {
          order: 0;
          flex: 0 1 auto;
          align-self: flex-end;
          padding-bottom: 5px;
          .opc-button {
            height: 2.7em;;
          }
      }
      .tag-list {
          overflow: hidden;
          display: inline-box;
          display: -webkit-inline-box;
		  -webkit-line-clamp: 1;
		  line-clamp:1;
          -webkit-box-orient: vertical;
          .tag {
              .tag-type {
                  display: block;
                  margin-right: 0;
              }
              .tag-image,.image {
                  display: inline-block;
                  img {
                      max-width: 30px;
                      max-height: 30px;
                      margin-bottom: -3px;
                      margin-right: 5px;
                  }
                  svg, i.fa {
                      height: 30px;
                      width: 30px;
                      margin-bottom: -2px;
                      padding: 4px;
                  }
              }
              div {
                  display: inline-block;
              }
          }
      }
  }
  
  .timeline li.activity:nth-of-type(2n+2) {
      .timeline-panel .tl-footer {
          padding: 0 10px 5px 0;
          .left {
              padding: 0 0 0 10px;
          }
      }
      .timeline-panel {
          float: right;
          padding: 10px 10px 10px 10px;
      }
      .timeline-panel:before {
          border-left-width: 0;
          border-right-width: 15px;
          left: -15px;
          right: auto;
      }
      .timeline-panel:after {
          border-left-width: 0;
          border-right-width: 14px;
          left: -14px;
          right: auto;
      }
  }
  /** timeline circle icons **/
  
  .timeline li .tl-circ {
      position: absolute;
      top: 31px;
      left: 50%;
      text-align: center;
      background: $theme-color-1;
      color: #ffffff;
      width: 20px;
      height: 20px;
      line-height: 20px;
      margin-left: -10px;
      border: none;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
      font-size: 2rem;
      svg, i.fa {
          position: relative;
          left: 0px;
      }
  }
  
  .timeline li.done .tl-circ {
      top: 28px;
      width: 28px;
      height: 28px;
      line-height: 28px;
      margin-left: -14px;
  }
  /** timeline content **/
  
  .tl-heading {
      border-bottom: none;
      padding-bottom: 0.4rem;
      margin-bottom: 0.4rem;
      p.date {
          font-size: 0.9em;
          margin: 0;
      }
      p.subtitle {
          font-size: 1em;
          margin: 0;
          font-weight: normal;
          float: right;
      }
      h4 {
          margin: 0;
          padding: 0;
          color: #333;
          font-weight: bold;
          font-size: 1.1em;
      }
      * {
          overflow: hidden;
          display: -webkit-box;
          display: flex;
		  -webkit-line-clamp: 1;
		  line-clamp:1;
		  -webkit-box-orient: vertical;
		  box-orient: vertical;
      }
  }
  .tl-body{
		overflow:hidden;
		max-height: 36px;
	}
  .tl-body p,
  .tl-body ul {
      margin-bottom: 0;
      overflow: hidden;
      display: -webkit-box;
      display: flex;
	  -webkit-line-clamp: 2;
	  line-clamp:2;
	  -webkit-box-orient: vertical;
	  box-orient: vertical;
      font-size: 0.9em;
  }
  
  .tl-status {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 20px;
      background: #ddd;
      padding: 3px;
      font-size: 1.5rem;
      &.done {
          background: rgb(97, 168, 97);
          svg, i.fa {
              color: #fff;
              -moz-transform: rotate(-10deg);
              -ms-transform: rotate(-10deg);
              -o-transform: rotate(-10deg);
              -webkit-transform: rotate(-10deg);
          }
      }
  }
  
  .timeline li.activity:nth-of-type(2n+2) .timeline-panel .tl-status {
      right: 0;
      left: inherit;
  }
  
  @media (max-width: 800px) {
      .page-header h1 {
          font-size: 1.8em;
      }
      ul.timeline:before {
          left: 10px;
      }
      .tldate {
          width: 100%;
      }
      .timeline li,
      .timeline.compact li {
          margin-bottom: 25px;
      }
      ul.timeline li .timeline-panel {
          width: calc(100% - 35px);
          width: -moz-calc(100% - 35px);
          width: -webkit-calc(100% - 35px);
      }
      ul.timeline li .tl-circ {
          top: 31px;
          left: 0px;
          margin-left: 0;
      }
      ul.timeline li.done .tl-circ {
          top: 27px;
          left: 39px;
      }
      ul.timeline li .tldate {
          margin: 0;
      }
      ul.timeline li .timeline-panel,
      ul.timeline.compact li .timeline-panel {
          float: right;
          padding: 10px 10px 10px 10px;
          .tl-status {
              right: 0;
              left: inherit;
              top: 0;
          }
      }
      ul.timeline li .timeline-panel:before {
          border-left-width: 0;
          border-right-width: 15px;
          left: -15px;
          right: auto;
      }
      ul.timeline li .timeline-panel:after {
          border-left-width: 0;
          border-right-width: 14px;
          left: -14px;
          right: auto;
      }
      ul.timeline li .timeline-panel:hover:after {
          
		  display:none;
      }
      .timeline li.activity .timeline-panel .tl-footer {
		  padding: 0 10px 5px 0;
          .left {
			  padding: 0 0 0 10px;
			}
		}
		.timeline li.activity .timeline-panel .tl-body {
			max-height: 36px;
    		overflow: hidden;
		}
      .timeline li.divider,
      .timeline li.divider.bottom,
      .timeline li.divider.top {
          margin: 10px 0 10px 0;
	  }
	  .timeline li.is-active .timeline-panel {	
		&:after{
			border-right: 14px solid #efefef;
			border-left: 0 solid #efefef;
		}

	  }
  }



  //Touchpoints

  li.touchpoint{
	position: absolute;
    left: 50%;
	text-align: center;
	position: relative;
	margin-bottom: 0;
	margin-top: 10em;
	.tp-empl-img{
		border-radius: 50%;
		border: solid 0.25em #5a5c3c;
		height: 4.7em;
		width: 4.7em;
		background-size: contain;
		position: absolute;
		left: -7.5em;
		top: -1.8em;
	}
	.tp-vor{
		border-radius: 50%;
		height: 1.9em;
		width: 1.9em;
		background: #efefef;
		color: #5a5c3c;
		font-size: 1.9em;
		position: absolute;
		left: -0.95em;
    	top: -1.3em;
		border: solid 0.1em #fff;

	}
	.tp-nach{
		border-radius: 50%;
		height: 1.9em;
		width: 1.9em;
		background: #efefef;
		color: #5a5c3c;
		font-size: 1.9em;
		position: absolute;
		left: -0.95em;
    	top: 5.25em;
		z-index: 2;
		border: solid 0.1em #fff;
		padding-top: 0.3em;
	}
	.tp-circle{
		border-radius: 50%;
		background: #5a5c3c;
		border:solid 0.4em #fff;
		width:11em;
		height:11em;
		margin-left:-5.5em;
		padding-top: 3.1em;
		position: relative;
		z-index: 2;
		display:block;
		span{
			color:#fff;
			font-size:2.5em;
		}
		.tp-connect{
			border-radius: 50%;
			border: solid 0.25em #fff;
			height: 1.6em;
			width: 1.6em;
		}
		.tp-connect-ma{
			background: #8C981F;
			position: absolute;
			left: -0.9em;
			top: 4.2em;
		}
		.tp-connect-cl{
			background: #5a5c3c;
			position: absolute;
			left: 8.8em;
			top: 1.7em;
		}
		.tp-connect-ex{
			background: #ccc;
			position: absolute;
			left: 7.6em;
			top: 0.3em;
		}
		.tp-jlc{
			background: #5a5c3c;
			height: 2.5em;
			width: 2.5em;
			color:#fff;
			padding-top:0.3em;
			position: absolute;
			left: 0.6em;
			top: 7.8em;
		}
		.tp-empl-label{
			background:#efefef;
			padding:0.1em;
			color:#707070;
			font-size:1.2em;
			position:absolute;
			right: 7.4em;
			top: 1.4em;
			white-space: nowrap;
			font-weight:600;
		}
		.tp-date{
			padding:0.1em;
			color:#707070;
			font-size:1em;
			position:absolute;
			right: 10.6em;
			top: 7.1em;
			white-space: nowrap;
		}
		.tp-time{
			padding:0.1em;
			color:#707070;
			font-size:1em;
			position:absolute;
			right: 10.6em;
			top: 8.6em;
			white-space: nowrap;
		}
		.tp-remark{
			background:#efefef;
			padding:0.1em;
			color:#707070;
			font-size:1.3em;
			position:absolute;
			left: 7.3em;
			top: 3em;
			white-space: nowrap;
			font-weight:600;
		}
		.tp-procstate{
			background:#efefef;
			padding:0.1em;
			color:#707070;
			font-size:1em;
			position:absolute;
			left: 9.45em;
			top: 6em;
			white-space: nowrap;
		}
		.tp-contact{
			background:#efefef;
			padding:0.1em;
			color:#707070;
			font-size:1em;
			position:absolute;
			left: 9.45em;
			top: 7.6em;
			white-space: nowrap;
		}
	}
		
  }

  
  //Automation timeline

ul.timeline.timeline-automation {
	padding-bottom: 0px;
	&:before{
		left: 20px;
		margin-bottom: 4em;
	}
	li.step{
		margin-bottom: 0em;
    	height: 6em;
		
		.circle{
			border-radius: 50%;
			height: 2em;
    		width: 2em;
			background:#efefef;
			color:#5a5c3c;
			font-size: 1.5em;
			padding-top: 0.35em;
			text-align: center;
		}
		.text{
			position: relative;
			left: 4em;
			top: -2.3em;
		}
	}
}