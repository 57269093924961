/*Projektspezifische Styles: hier!*/


/*Navigation Logo - Hoehe anpassen*/
.mainnav-wrapper .client-name {
    height: 60px;
}
.mainnav-wrapper .mainnav-content {
    height: calc(100vh - 7rem - 1rem - 45px - 60px);
}
.mainnav-wrapper .client-name .client-info {
    float: none;
}

.list-item[data-op-type="success"] {
	background: $success-color;
	color: #fff;

	&:hover{
		color: $success-color;
	}
}

/*	Activity-0025-MSG.oc
*/
.activity-0025.panel.panel-beside:not([data-op-navigation="0900.0002.0003"]){
	.filter-set-list .filter-set-list-item #ActivityUser-Activity_TotalExclVat{
		display:none;
	}
}

/* 	AddrRelation-2300-MSG.oc
*/
@include mq($min-width: map-get($breakpoints, m)) {
	.addrrelation-2300.panel.panel-beside{
		position:fixed; 
		width:calc(100% - 6rem); 
		height:125px
	}
	.addrrelation-2300.panel.panel-beside + .panel{
		margin-top:125px;
	}
}

.art-search-list {
    .panel-body {
        .list-item-column {
			&:nth-last-child(n + 11):first-child ~ :nth-last-child(4) {
				text-align: left;
			}
		}
	}
}

#missal {
	.item-list-body .list-item .list-item-column{
		&[data-op-year="current"]{
			background: rgba($chart-color-1, 0.15) !important; 
		}
		&[data-op-year="prev"]{
			background: rgba($chart-color-2, 0.15) !important;
		}
		&[data-op-year="prevprev"]{
			background: rgba($chart-color-3, 0.15) !important;
		}
	}
}

#SalDoc-FilterSalProcLevelCd .filteritemamount,
#SalDoc-FilterShortCut .filteritemamount,
#SalDoc-FilterRefereeNo .filteritemamount,
#SalDoc-FilterTVB .filteritemamount,
#SalDocUser-FilterSalProcLevelCd .filteritemamount,
#SalDocUser-FilterShortCut .filteritemamount,
#SalDocUser-FilterRefereeNo .filteritemamount,
#SalDocUser-FilterTVB .filteritemamount{
	display:none;
}

/*
	Verkaufsdokumente DocStateCd Icons
*/
[data-op-item-marker-value^="SalDoc-FilterDocStateCd"],[data-op-item-marker-value^="SalDocUser-FilterDocStateCd"],[data-op-item-marker-value^="SalDocUserSubmittenten-FilterDocStateCd"]{
    &:before {
		content: "\f15c";
    }
}

[data-op-item-marker-value="SalDoc-FilterDocStateCd-SalDoc.DocStateCd=40"],[data-op-item-marker-value="SalDocUser-FilterDocStateCd-SalDoc.DocStateCd=40"],[data-op-item-marker-value="SalDocUserSubmittenten-FilterDocStateCd-SalDoc.DocStateCd=40"] {
    color: $saldoc-docstatecd-color-40;
    &:before {
		font-weight: 900;
    }
}

[data-op-item-marker-value="SalDoc-FilterDocStateCd-SalDoc.DocStateCd=30"],[data-op-item-marker-value="SalDocUser-FilterDocStateCd-SalDoc.DocStateCd=30"],[data-op-item-marker-value="SalDocUserSubmittenten-FilterDocStateCd-SalDoc.DocStateCd=30"] {
    color: $saldoc-docstatecd-color-30;
    &:before {
        font-weight: 900;
    }
}

[data-op-item-marker-value="SalDoc-FilterDocStateCd-SalDoc.DocStateCd=20"],[data-op-item-marker-value="SalDocUser-FilterDocStateCd-SalDoc.DocStateCd=20"],[data-op-item-marker-value="SalDocUserSubmittenten-FilterDocStateCd-SalDoc.DocStateCd=20"] {
    color: $saldoc-docstatecd-color-20;
    &:before {
        font-weight: lighter;
    }
}

[data-op-item-marker-value="SalDoc-FilterDocStateCd-SalDoc.DocStateCd=10"],[data-op-item-marker-value="SalDocUser-FilterDocStateCd-SalDoc.DocStateCd=10"],[data-op-item-marker-value="SalDocUserSubmittenten-FilterDocStateCd-SalDoc.DocStateCd=10"] {
    color: $saldoc-docstatecd-color-10;
    &:before {
        font-weight: lighter;
    }
}

// Filter ausblenden (Nur als Init verwendet)
.opc-filter-set-opportunity .filter-set-list-item{
	&:nth-child(8),&:nth-child(9),&:nth-child(10),&:nth-child(11){
		display:block;
	}
}