$_opc-table-padding: 1rem !default;

$_opc-table-sort-icon-color: rgba(#000, 0.15) !default;
$_opc-table-sort-icon-active-color: rgba(#000, 1) !default;

$_opc-table-sort-icon-none: $op-icon-sort-none;
$_opc-table-sort-icon-asc: $op-icon-sort-asc;
$_opc-table-sort-icon-desc: $op-icon-sort-desc;

table {
	width: 100%;
	text-align: left;
	table-layout: fixed;
	border-collapse: collapse;

	thead {
		tr {
		}

		th {
			padding: $_opc-table-padding;
		}
		td {
			padding: $_opc-table-padding;
			overflow:hidden;
			text-overflow:ellipsis;
		}
	}

	tbody {
		tr {
			border-bottom: 1px solid #ccc;
		}

		th {
			padding: $_opc-table-padding;
		}
		td {
			padding: $_opc-table-padding;
			overflow:hidden;
			text-overflow:ellipsis;
		}
	}

	&[data-op-type="tablesort"] {
		thead {
			tr {
				th {
					span {
						float: right;
						&::before {
							background-size: contain;
							background-repeat: no-repeat;
							background-position: center;
							content: "";
							display: block;
							width: 1rem;
							height: 1rem;
						}
					}

					&[data-op-sort-direction] {
						cursor: pointer;
					}

					&[data-op-sort-direction=""] span::before {
						background-image: inline-svg(
							$_opc-table-sort-icon-none,
							$_opc-table-sort-icon-color
						);
					}

					&[data-op-sort-direction="asc"] span::before {
						background-image: inline-svg(
							$_opc-table-sort-icon-asc,
							$_opc-table-sort-icon-active-color
						);
					}

					&[data-op-sort-direction="desc"] span::before {
						background-image: inline-svg(
							$_opc-table-sort-icon-desc,
							$_opc-table-sort-icon-active-color
						);
					}
				}
			}
		}
	}
}
