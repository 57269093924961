.panel {
    background: #fff;
    //    border: 1px solid #ccc;
    .panel-head {
        padding: 1.5rem 0;
        margin: 0 2rem;
        border-bottom: 1px solid #ccc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
			line-height:1.3;
            a {
                color: $brand-color-1;
				cursor:pointer;
                &:hover {
                    color: darken($brand-color-1,10%);
                }
            }
        }
        &[data-op-style="clean"] {
            border-bottom: none;
        }
        .panel-head-actions {
            [data-op-type="link"] {
                color: #000;
            }
        }
    }
    .panel-body {
        padding: 2rem;
		@include mq($max-width: map-get($breakpoints, s)) {
			padding: 1rem;
		}
        .panel-body-inner {
            height: 100%;
        }

		.opc-item-list:only-child{
			margin-bottom:0;
		}
    }
    /*.panel-body.panel-beside {
        background-color: #f7f7f7;
    }*/
    &[data-op-panel-height] .panel-body {
        overflow-y: auto;
        overflow-x: auto;
    }
    &[data-op-panel-height="xs"] {
        .panel-body {
            max-height: 15rem;
        }
    }
    &[data-op-panel-height="s"] {
        .panel-body {
            max-height: 20rem;
        }
    }
    &[data-op-panel-height="m"] {
        .panel-body {
            max-height: 30rem;
        }
    }
    &[data-op-panel-height="l"] {
        .panel-body {
            max-height: 40rem;
        }
    }

	/*+ .panel .panel-head{
		margin-top:2rem;
	}*/
}

.panel-beside {
    background-color: #f8f8f8;
    &+.opc-tabs,  &+.panel[data-op-style="info"]{
        margin-top: 2rem;
    }
}