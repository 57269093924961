/*
	MisSal
*/

#missal {
	form,div{
		&[data-ecrm-mistyp] [data-ecrm-mistyp] {
			display: none;
		}
		&[data-ecrm-mistyp="B"] [data-ecrm-mistyp="B"],
		&[data-ecrm-mistyp="C"] [data-ecrm-mistyp="C"],
		&[data-ecrm-mistyp="R"] [data-ecrm-mistyp="R"] {
			display: block;
		}
	}
    form[data-ecrm-mistyp] .form-horizontal:first-child {
        float: left;
        padding-right: 15px;
    }
    form[data-ecrm-mistyp] .opc-input,
    form[data-ecrm-mistyp] .opc-button,
    form[data-ecrm-mistyp] .opc-textarea {
        width: 100%;
    }
    .opc-item-list .item-list-body .list-item:first-child .list-item-column {
        font-weight: 600;
	}
    .item-list-head,
    .item-list-body {
        padding: 0;
        .list-item {
            padding: 0;
        }
        .list-item-column {
            padding-right: 0.5rem;
            text-align: right;
            //min-height: 50px;
            //white-space: nowrap;
            button i,
            button svg, i.fa {
                margin-left: 15px;
            }
			align-self: stretch;
			&::before{
				text-align:left;
				flex: 0 0 70%;
			}
        }
    }
    .item-list-head-period .list-item-column[data-column-width="2"] {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .item-list-head-period .list-item-column[data-column-width="3"] {
        padding-left: 1rem;
        padding-right: 0.5rem;
    }
    .item-list-head-period .list-item-column {
        opacity: 1;
    }
    .list-item-column:nth-child(1) {
        flex: 2;
        text-align: left;
    }
    .list-item-column:nth-child(2) {
        text-align: left;
		padding-top:0.5rem !important;
		padding-bottom:0.5rem !important;
    }
    .item-list-body:not([data-op-style="context-menu"]) {
        .list-item-column {
            padding: 1.5rem 0.5rem 1.5rem 0.5rem;
        }
    }
    .opc-item-list .list-item .list-item-column[data-op-style="context-menu"] {
		padding: 0;
		padding-top: 0.5rem;
    }

	.item-list-body .list-item .list-item-column {
		&[data-op-year="current"]{
            background: rgba($chart-color-1, 0.15);
        }
		&[data-op-year="prev"]{
            background: rgba($chart-color-2, 0.15);
        }
		&[data-op-year="prevprev"]{
            background: rgba($chart-color-3, 0.15);
        }
    }

    .opc-form-field {
        margin-bottom: 1em;
    }
    .opc-row .opc-col {
        margin-bottom: 0px;
    }
    .dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        background: #ccc;
    }
}