/*
	Verkaufsdokumente DocStateCd Icons
*/
[data-op-item-marker-value^="SalDoc-FilterDocStateCd"],[data-op-item-marker-value^="SalDocUser-FilterDocStateCd"]{
    &:before {
		content: "\f15c";
    }
}

[data-op-item-marker-value="SalDoc-FilterDocStateCd-40"],[data-op-item-marker-value="SalDocUser-FilterDocStateCd-40"] {
    color: $saldoc-docstatecd-color-40;
    &:before {
		font-weight: 900;
    }
}

[data-op-item-marker-value="SalDoc-FilterDocStateCd-30"],[data-op-item-marker-value="SalDocUser-FilterDocStateCd-30"] {
    color: $saldoc-docstatecd-color-30;
    &:before {
        font-weight: 900;
    }
}

[data-op-item-marker-value="SalDoc-FilterDocStateCd-20"],[data-op-item-marker-value="SalDocUser-FilterDocStateCd-20"] {
    color: $saldoc-docstatecd-color-20;
    &:before {
        font-weight: lighter;
    }
}

[data-op-item-marker-value="SalDoc-FilterDocStateCd-10"],[data-op-item-marker-value="SalDocUser-FilterDocStateCd-10"] {
    color: $saldoc-docstatecd-color-10;
    &:before {
        font-weight: lighter;
    }
}

.saldoc-info {
    font-size: 1.5rem;
    display: none;
    >div {
        display: block;
        margin-right: 2rem;
        .opc-form-field {
            margin-bottom: 0;
        }
        .form-field-label {
            font-family: $_opc-base-font-family;
            font-size: 1.4rem;
        }
    }
    @include mq($min-width: map-get($breakpoints, xl)) {
        display: flex;
        align-items: center;
        float: left;
    }
}

/* 	SalDoc-0027.oc
*/
.saldoc-0027 table {
	width:100%;

	td:first-child{
		padding-left:0;
	}

	td:last-child, 
	td:nth-child(2), 
	td:nth-child(3),
	input{
		text-align:right;
	}
}


/* 	SalDoc-0030.oc / SalDoc-0080.oc
*/
.saldoc-0030.opc-item-list .item-list-head .list-item-column, 
.saldoc-0030.opc-item-list .item-list-body .list-item-column
{

	.opc-form-field{
		margin-bottom:0px;
		.form-field-label{
			display:none;
		}
	}

	&:nth-child(5){
		.form-field-element{
			width:100%;
		}
	}

    &:nth-child(6){
		padding-left:0.5rem;
	}
}

.saldoc-0030.opc-item-list .item-list-body .list-item.entry-form{
	background-color:#F8F8F8;

	.list-item-column{

		&:first-child{
			visibility:hidden;
		}
	}

	@include mq($max-width: map-get($breakpoints, m)) {
		padding:1rem 2rem;

		.newItem, .form-field-element, .opc-form-field, button{
			width:100%;
		}

		.list-item-column{
			&:first-child{
				visibility:visible;
				font-weight:600;
			}

			&::before{
				display:none;
			}
		}
    }
}

.saldoc-0030.opc-item-list .item-list-foot .list-item-column {
    &:nth-child(2) {
		.form-field-element{
			float:right;
		}
	}
}

.saldoc-0030.opc-item-list input.opc-input[data-op-origintype="R"] {
	text-align:right;
}

/* 	SalDoc-0040.oc
*/
.saldoc-0040 .opc-col .view{
	padding:1rem;
	border:1px solid #eee;
	height:100%;
	[data-op-style="context-menu"] .opc-dropdown-button{
		position:absolute;
		right: calc( 10px + 1rem );
    	top: 1rem;
	}
}
.saldoc-0040 .opc-col > .opc-form-field .form-field-element{
	float:left;
	width:auto;
	~ *{
		margin-left:1em;
	}
}

/* 	saldocList Widget Options
*/
[data-options-lc="SalDoc-112"] .dashboard-options{
	.opc-form-field[data-id="salproclevelcd"]{
		display: flow-root;
		.opc-form-field 
		{
			float: left;
			margin-right: 2rem;
			margin-bottom:0;
			.form-field-label{
				font-weight:normal;
			}
		}
		
	}
}