    /* Ergaenzungen Datepicker */

	
    .opc-time .daterangepicker-input+.daterangepicker-calendar.open {
		min-width:auto;
		max-width:250px;
	}
    .opc-date .daterangepicker-input+.daterangepicker-calendar.open {
        max-width: 400px;
	}
	.opc-date,.opc-time{
		&::after{
			color: #aaa;
			font-family: 'Font Awesome 5 pro';
			position: absolute;
			right: 10px;
			top: 10px;
			pointer-events:none;
			-webkit-animation: none;
			-moz-animation: none;
			-ms-animation: none;
			-o-animation: none;
			animation: none;
		}
	}
    .opc-time {
		&::after{
			content: "\f017";
		}
	}
    .opc-date {
        &::after{
			content: "\f133";
		}
    }
    
    .opc-time .daterangepicker-input+.daterangepicker-calendar.open,
    .opc-date .daterangepicker-input+.daterangepicker-calendar.open,
    .daterangepicker-calendar {
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
		margin-top: 0;
    }
    
    .daterangepicker-calendar.inline {
        top: 0px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
		border:none;
    }
    
    .form-field-element {
        .daterangepicker-calendar {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
			border: 1px solid #bbb;
        }
    }
    
    .filter-body .filter-body-search{
		.daterangepicker-calendar,.daterangepicker-calendar.open {
			max-width: 100%;
			border:none;
			margin-top:0;
			width: 100%;
			.daterangepicker-rContainer,.daterangepicker-days{
				width: 100%;
				.dayContainer{
					width: 100%;
					max-width: 100%;
					min-width: 100%;
				}
				.daterangepicker-day{
					max-width:none;
				}
			}
		}
		.daterangepicker-months input {
			padding: 0;
			&.cur-year {
				padding: 0 0 0 0.5ch;
			}
		}
	}

	.daterangepicker-mobile{
		width:100%;
	}
	input[type="time"]::-webkit-calendar-picker-indicator { 
		background: none; 
		display:none; 
	}



.daterangepicker-calendar {
	margin-top:-3px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	border: 1px solid #bbb;
	box-shadow:none;
	font-family: $_opc-base-font-family;
	font-weight: $_opc-base-font-weight;
	line-height: $_opc-base-line-height;
	-webkit-font-smoothing: antialiased;
	&:before,&:after{
		border:none;
	}
}
.daterangepicker-calendar.inline {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	:before,:after{
		border:none;
	}
}