.form-horizontal {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-align: start;
    -moz-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.form-horizontal>* {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    padding-right: 15px;
}

.form-horizontal>*:last-child {
    padding: 0;
}

.opc-checkbox{
	height: calc( 2rem + 2px);
}

.opc-form-field {
	&:only-child{
		margin-bottom:0;
	}

	.form-field-label.mandatory:after {
		content:" *";
	}
}

.opc-form-field-float-left{
	float: left;
    margin-right: 2rem;
}

.opc-form-field-float-left + .opc-form-field:not(.opc-form-field-float-left) {
	clear:both;
}

/* Informationen unter "Info" Tabs */
.form-field-element.info {
	:not(:last-child){
		margin-right:1em;
		
	}
}

.list-item-column{
	.form-field-element {
		width: 95%;
		position: relative;

		input[type="text"],
		input[type="password"],
		input[type="email"],
		input[type="number"],
		input[type="tel"],
		input[type="search"],
		input[type="url"],
		input[type="date"],
		textarea {
			width: 100%;
		}
	}
}


/*
    Legacy Firefox implementation treats all flex containers
    as inline-block elements.
*/

@-moz-document url-prefix() {
    .flex-container {
        width: 100%;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}


.pwd-strength {
	padding-left: 1rem;
	margin-bottom: 0rem;
}

form fieldset.opc-row{
	margin-left:0;
	margin-right:0;
}

/*
	Select readonly
*/
.opc-select {
	&[readonly]{
		cursor: not-allowed;
		pointer-events: none;
		opacity: $_opc-form-field-opacity-disabled;
		background: $_opc-form-field-bg-disabled !important;
	}
}



/*
	Input Group
*/
.form-field-element.form-field-input-group{
	position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;
	.input-group-text{
		display: flex;
    	align-items: center;
		text-align: center;
    	white-space: nowrap;
		padding:0 1rem;
		background: $color-greylight;
		border: $_opc-form-field-border;
	}
	.input-group-text:last-child{
		margin-left: -1px;
	}
	input{
		flex: 1 1 auto;
		position: relative;
		width: 1%;
		min-width: 0;
	}
	input:not(:first-child){
		margin-left: -1px;
	}

	>.input-group-text+.form-field-element input{
		margin-left:-1px;
	}
}

/*
	Form Field with Button Inside 
*/
.opc-linked-field, .opc-inline-form{
	position:relative;

	.opc-form-field{ 
		width:calc(100% - 8rem);

		.form-field-label{
			height:1.4em;
		}

		.form-field-element{
			width:100%;
			.opc-input:read-only{
				background-color:#fff !important;
				cursor: text;
				pointer-events:all;
			}

			.opc-input[disabled]{
				pointer-events:none;
				background: #eee !important;
			}

			.remove-icon::after {
				color: $color-grey;
				font-family: 'Font Awesome 5 pro';
				position: absolute;
				right: 0px;
				top: 0px;
				padding: 0.5em 0.8em;
    			font-size: 1.2em;
				content: "\f00d";
				-webkit-animation: none;
				-moz-animation: none;
				-ms-animation: none;
				-o-animation: none;
				animation: none;
				cursor:pointer
			}
			.remove-icon.hide{
				display:none;
			}
		}
	}
	.opc-form-field.is-invalid + .opc-button{
		border-color: $error-color;
		color: $_opc-form-field-color-invalid;
	}

	.opc-button{
		position: absolute;
		width: 8rem;
		height: 4rem;
		top: calc(1.4em + 0.5rem);
		right: 0px;

		border-left:0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	&.is-valid{
		.opc-button{
			background-color: $success-color;
			border-color: $success-color;
			color:#fff;
		}

		&:hover{
			.opc-button{
				color: $_opc-button-secondary-color;
			}
		}
	}

	&[data-op-style="clean"]{
		.opc-button{
			top:0px;
		}
	}

	&[data-op-size="small"]{
		.opc-form-field{ 
			width:calc(100% - 4rem);
		}

		.opc-button{
			width: 4rem;
			padding:0;
			text-align:center;
		}
	}
}

form fieldset.invisible{
	margin:0;
	padding:0;
	border:none;
	&:disabled{

		.opc-linked-field{
			.opc-form-field{ 
				
				width:100%;
				
				.form-field-element{
					.opc-input:read-only{
						background-color:$color-greylight !important;
						cursor: not-allowed;
						pointer-events: none;
					}
					
					.remove-icon::after {
						display:none;
					}
					.remove-icon.hide{
						display:none;
					}
				}
			}
			.opc-button{
				display:none;
			}
	
		}
	}
}

//Textarea disabled scrollable
.opc-textarea {
	&:disabled, &:read-only {
		pointer-events: all;
	}
}