/*###############################################

@Title: Dropdown button
@Description:
This file contains the dropdown button styles

###############################################*/

$_opc-dropdown-color-background: #fff !default;
$_opc-dropdown-color-shadow: rgba(0,0,0,.2) !default;
$_opc-dropdown-color-text: #212529 !default;
$_opc-dropdown-color-divider: #e9ecef !default;
$_opc-dropdown-color-text-hover: #16181b !default;
$_opc-dropdown-color-background-hover: #f8f9fa !default;

$_opc-dropdown-border-radius: .25rem !default;
$_opc-dropdown-border-color: rgba(0,0,0,.2) !default;

$_opc-dropdown-ovleray-zindex: 1060 !default;

.opc-dropdown-button{
    display: inline-block;

    >.opc-button{
        &:after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: .255em;
            vertical-align: .255em;
            content: "";
            border-top: .3em solid;
            border-right: .3em solid transparent;
            border-bottom: 0;
            border-left: .3em solid transparent;
        }
    }

    .dropdown-button-content{
        display: none;
    }
}

.opc-dropdown-button-content[data-op-caller-uuid]{
    position: absolute;
    top: -1000;
    left: -1000;
    z-index: $_opc-dropdown-ovleray-zindex;
    display: block;
    padding: 1px;
    white-space: normal;
    background-color: $_opc-dropdown-color-background;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid $_opc-dropdown-border-color;
    border-radius: $_opc-dropdown-border-radius;
    -webkit-box-shadow: 0 5px 10px $_opc-dropdown-color-shadow;
    box-shadow: 0 5px 10px $_opc-dropdown-color-shadow;

    min-width: 10rem;
    padding: .5rem 0;
    color: $_opc-dropdown-color-text;
    text-align: left;
    list-style: none;


    > .overlay-content{
        > a{
            display: block;
            padding: .25rem 1.5rem;
            clear: both;
            color: $_opc-dropdown-color-text;
            text-align: inherit;
            white-space: nowrap;
            background-color: transparent;
            border: 0;
            text-decoration: none;

            &:hover{
                color: $_opc-dropdown-color-text-hover;
                text-decoration: none;
                background-color: $_opc-dropdown-color-background-hover;
            }
        }

        > .divider{
            height: 0;
            margin: .5rem 0;
            overflow: hidden;
            border-top: 1px solid $_opc-dropdown-color-divider;
        }
    }
}