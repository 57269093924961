/* 	Fiacc-0010.oc
*/

.fiacc-0010-bonitaet.opc-item-list .list-item-column:nth-child(3) {
	&[data-op-type="error"] {
		font-weight:bold;
        color: $error-color;
    }
}

.fiacc-0010.opc-item-list .list-item-column:nth-child(5) {
	padding-right: 25px;
}